<template>
  <div class="wbobyy">
    <div id="wrapper">
        <div class="input-data">
            <input type="text" v-model="Noitem" oninput="value=value.replace(/[^\d]/g,'')" @keyup.enter="AdminDataSelect(Noitem)" required>
            <div class="underline">
              <i class="el-icon-circle-close" v-show="Noitem.length > 0" style="position: absolute;top: -30px;right: 60px;color:#9fa1a7;" @click="Noitem = ''"></i>
              <el-button type="primary" style="position: absolute;top: -35px;right: 1px;" size="mini" icon="el-icon-search" @click="AdminDataSelect(Noitem)"></el-button>
            </div>
            <label>{{ this.$t('All.请输入工单号') }}</label>
        </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      Noitem: '',
      Numbers: ''
    }
  },
  created () {
  },
  mounted () {
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  methods: {
    ...mapMutations(['Gongdanfun', 'TabComponentFun']),
    async AdminDataSelect (row) {
      const { data: res } = await this.$http.get(`/api/Semifinished/Geterpwuliao?gongdan=${row}&cq=${this.$store.state.Login.Factory}`)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.Gongdanfun(row)
      this.TabComponentFun('ICUser')
      this.Addtab(this.Noitem + this.$t('All.追溯记录'), 'ICUser')
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobyy{
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: fixed;
  height: 0px;
  width: 300px;
}
  /** talbe表头置顶**/
  /deep/ .el-table {
    overflow: visible;
 }

 /deep/ .el-table__header-wrapper {
    position: sticky;
    top: 0px;
    z-index: 10;
 }
</style>
